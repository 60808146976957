<template>
  <ac-select
    label="Company"
    :value="selectedCompany"
    :items="companies"
    class="company-select me-3"
    @change="onCompanySelect"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    AcSelect: () => import('@/components/AcSelect'),
  },
  computed: {
    ...mapGetters('app', ['companies', 'selectedCompany']),
  },
  methods: {
    ...mapMutations('app', ['SET_SELECTED_COMPANY']),
    onCompanySelect(id) {
      this.SET_SELECTED_COMPANY(id)
      this.$emit('change', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
